<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.86212 6V3.77778C7.86212 3.04107 8.17921 2.33453 8.74364 1.81359C9.30807 1.29266 10.0736 1 10.8718 1C11.67 1 12.4356 1.29266 13 1.81359M2.20388 6.88889H9.66794C10.3328 6.88889 10.8718 7.38635 10.8718 8V11.8889C10.8718 12.5025 10.3328 13 9.66794 13H2.20388C1.539 13 1 12.5025 1 11.8889V8C1 7.38635 1.539 6.88889 2.20388 6.88889Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
